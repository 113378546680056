import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import SignInForm from '../components/layoutComponents/signIn/SignIn'
import PageMeta from '../components/PageMeta/PageMeta'
import DashboardTiles from '../components/DashboardTiles/DashboardTiles'
import { DashboardTiles as IDashboardTiles } from '../types'
import SaveIcon from '../assets/love-heart.svg'
import NewsletterIcon from '../assets/newsletter-icon.svg'
import ReaderTreatsIcon from '../assets/readertreat-icon.svg'
import { LoginContext } from '../lib/utils'
import * as SignInStyles from '../components/layoutComponents/signIn/SignIn.module.scss'
import Logomark from '../components/Logomark/Logomark'

const Dashboard = () => {
  const { usr } = useContext(LoginContext)
  const [data, setData] = useState<any>({})

  useEffect(() => {
    usr && setData(usr)
  }, [usr])

  const tiles: IDashboardTiles[] = [
    {
      title: 'Newsletters',
      link: '/dashboard/manage-subscriptions/',
      image: NewsletterIcon,
      description:
        'Going on holiday? Sign up for another Muddy county newsletter',
      linkText: 'Update Your Preferences'
    },
    {
      title: 'Reader Treats',
      link: '/reader-treats/',
      image: ReaderTreatsIcon,
      description:
        'Our luxe competitions, giveaways and gifts are the best in the business',
      linkText: 'Enter Our Reader Treats'
    },
    {
      title: 'My Favourites',
      link: '/favourites/',
      image: SaveIcon,
      description:
        "Save your favourites here and you'll never forget a brilliant Muddy recommendation",
      linkText: 'View Your Favourites'
    }
  ]
  return (
    <>
      <PageMeta
        pageData={{
          title: 'My Muddy Account | Muddy Stilettos',
          description: 'Welcome to your Muddy Account page'
        }}
      />
      <Header />
      <Intro removeMargin={true}>
        <Logomark />
        <h1>My Muddy Account</h1>
        {data && data.viewer && (
          <p>
            Logged in as{' '}
            <span className={SignInStyles.LoggedInAs}>{data.viewer.name}</span>
          </p>
        )}
      </Intro>
      {data && data.viewer ? (
        <>{tiles && <DashboardTiles tiles={tiles} />}</>
      ) : (
        <SignInForm />
      )}
      <Footer />
    </>
  )
}

export default Dashboard
