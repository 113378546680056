import React from 'react';
import * as DashboardStyles from './DashboardTiles.module.scss';
import { DashboardTiles as IDashboardTiles } from '../../types';
import { Link } from 'gatsby';

interface DashboardTilesProps {
    tiles: IDashboardTiles[]
}

const DashboardTiles = ({tiles} : DashboardTilesProps) => {
    return (
        <>
            <div className={DashboardStyles.Wrapper}>
                {tiles.map((tile) => 
                    <div key={tile.title} className={DashboardStyles.Tile}>
                        <div className={DashboardStyles.ImgWrap}>
                            <img src={tile.image} />
                        </div>
                        <div className={DashboardStyles.ContentWrap}>
                            <h3>{tile.title}</h3>
                            <p>{tile.description}</p>
                            <Link to={tile.link}>{tile.linkText}</Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default DashboardTiles;